<template>
  <section class="relative" id="about">
    <div class="max-w-6xl mx-auto px-4 lg:px-12 py-32 lg:py-40">
      <span class="bg"></span>
      <span class="bg-r"></span>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
        <div class="text-white leading-relaxed space-y-6">
          <h1 class="font-bold text-5xl md:text-6xl my-4">About us</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
          <p>
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur.
          </p>

          <a
            href="#"
            class="inline-block items-center box-border rounded-md whitespace-nowrap border border-gray-50 p-3 sm:p-4 lg:px-5 text-gray-100 font-normal lg:font-semibold btn-highlight text-sm lg:text-md z-50"
            >Apply for Beta Access</a
          >
        </div>
        <div>
          <img src="../assets/images/about.png" alt="About Photo" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "About",
};
</script>

<style lang="scss" scoped>
.bg {
  position: absolute;
  width: 642.49px;
  height: 457.72px;
  left: -270.43px;
  top: 64px;

  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 163, 75, 0.5) 0%,
    rgba(0, 122, 118, 0.05) 100%
  );
  filter: blur(100px);
}

.bg-r {
  position: absolute;
  width: 642.49px;
  height: 457.72px;
  right: -170.43px;
  top: 64px;

  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 163, 75, 0.5) 0%,
    rgba(0, 122, 118, 0.05) 100%
  );
  filter: blur(100px);
}
</style>
